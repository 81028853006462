import React from "react";

const Logo = ({ className }) => {
  return (
    <svg viewBox="0 0 195 61" className={className}>
      <g>
        <path d="M42.62,37.83v8.78H11.76v-6.96L27.12,21.8H12.24v-8.78h29.62v6.96L26.5,37.83H42.62z" />
        <path d="M76.27,38.07v8.54H48.34v-33.6h27.31v8.54H59.47v3.94h14.21v8.16H59.47v4.42H76.27z" />
        <path
          d="M87.43,46.45c-2.64-0.62-4.81-1.46-6.5-2.52l3.65-8.26c1.6,0.93,3.39,1.67,5.38,2.23
			c1.98,0.56,3.89,0.84,5.71,0.84c1.57,0,2.69-0.15,3.36-0.46c0.67-0.3,1.01-0.76,1.01-1.37c0-0.7-0.44-1.23-1.32-1.58
			c-0.88-0.35-2.33-0.74-4.34-1.15c-2.59-0.54-4.75-1.13-6.48-1.75s-3.23-1.65-4.51-3.07s-1.92-3.35-1.92-5.78
			c0-2.11,0.59-4.03,1.78-5.76c1.18-1.73,2.95-3.09,5.3-4.08c2.35-0.99,5.21-1.49,8.57-1.49c2.3,0,4.57,0.25,6.79,0.74
			c2.22,0.5,4.18,1.22,5.88,2.18l-3.41,8.21c-3.3-1.66-6.42-2.5-9.36-2.5c-2.91,0-4.37,0.7-4.37,2.11c0,0.67,0.43,1.18,1.3,1.51
			c0.86,0.34,2.29,0.7,4.27,1.08c2.56,0.48,4.72,1.03,6.48,1.66s3.29,1.64,4.58,3.05c1.3,1.41,1.94,3.33,1.94,5.76
			c0,2.11-0.59,4.02-1.78,5.74c-1.18,1.71-2.95,3.07-5.3,4.08s-5.21,1.51-8.57,1.51C92.78,47.38,90.07,47.07,87.43,46.45z"
        />
        <path d="M123.79,21.8h-9.84v-8.78h31.01v8.78h-9.84v24.82h-11.33V21.8z" />
        <path
          d="M157.68,45.13c-2.88-1.5-5.14-3.59-6.77-6.26s-2.45-5.69-2.45-9.05c0-3.36,0.82-6.38,2.45-9.05
			s3.89-4.76,6.77-6.26c2.88-1.5,6.13-2.26,9.74-2.26s6.86,0.75,9.74,2.26c2.88,1.5,5.14,3.59,6.77,6.26s2.45,5.69,2.45,9.05
			c0,3.36-0.82,6.38-2.45,9.05s-3.89,4.76-6.77,6.26s-6.13,2.26-9.74,2.26S160.56,46.63,157.68,45.13z M171.24,37.18
			c1.14-0.69,2.04-1.66,2.71-2.93c0.67-1.26,1.01-2.74,1.01-4.44s-0.34-3.18-1.01-4.44c-0.67-1.26-1.58-2.24-2.71-2.93
			c-1.14-0.69-2.41-1.03-3.82-1.03s-2.68,0.34-3.82,1.03c-1.14,0.69-2.04,1.66-2.71,2.93c-0.67,1.26-1.01,2.74-1.01,4.44
			s0.34,3.18,1.01,4.44c0.67,1.26,1.58,2.24,2.71,2.93c1.14,0.69,2.41,1.03,3.82,1.03S170.1,37.87,171.24,37.18z"
        />
      </g>
    </svg>
  );
};

export default Logo;
